import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentHeaderModule } from './layout/components/content-header/content-header.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactInfoComponent } from './commonComponent/contact-info/contact-info.component';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { ErrorComponent } from './miscellaneous/error/error.component';
import { Routes } from '@angular/router';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const routes: Routes = [
  { path: 'miscellaneous/error', component: ErrorComponent },
];

@NgModule({
  declarations: [
    ContactInfoComponent,
    ErrorComponent
  ],
  imports: [
    CommonModule, ContentHeaderModule, CardSnippetModule, NgbModule, SwiperModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  exports: [ContentHeaderModule, CardSnippetModule, NgbModule, ContactInfoComponent]
})
export class SharedModule { }
