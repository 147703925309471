<!-- Error page-->
<div class="row">
<div class="misc-wrapper">
    <a class="brand-logo" href="javascript:void(0);">
      <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="70" />
       -->
       <img class="img-fluid" src="assets/images/logo/logo-top.png" alt="esg-logo" class="ml-4 mt-4" routerlink="/home">
   
      
      </a
    >
    <div class="misc-inner p-sm-1">
      <div class="w-100 text-center">
        <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
        <p class="mb-2">Oops! 😖 The requested URL was not found on this server.</p>
        <a class="btn btn-primary mb-2 btn-sm-block" (click)="BackButton()" rippleEffect
          >Back to home</a
        >
        <!-- <img
          class="img-fluid"
          [src]="
            coreConfig.layout.skin === 'dark' ? 'assets/images/pages/error-dark.svg' : 'assets/images/pages/error.svg'
          "
          alt="Error page"
        /> -->
        <div>
        <img src="assets/images/pages/error/404.png" alt="Error-page" height="20%" width="30%" >
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- / Error page-->
  