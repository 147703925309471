<div class="row" id="footer">
  <div class="col-md-3">
    <div class="float-right">
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="assets/images/logo/logo-top.png" alt="banner" width="70%" height="auto"
            routerLink="home">
        </div>
      </div>

      <div class="row pt-2">
        <div class="col-12 text-justify">
          <h4>The last decade has seen drastic changes in businesses. With these changes, debates have risen on
            managerial interests vs investors interest, consumer satisfaction and needs.
          </h4>
        </div>
      </div>

    </div>
  </div>
  <div class="col-md-9">
    <div class="row pl-3">
      <div class="col-md-4 col-sm-6 pt-2">
        <h3 class="font-weight-bolder"><span id="footer-menu">Browse</span></h3>
        <div class="row pt-2 footerLink">
          <div class="col-12">
            <a routerLink="home">
              <h4>Home</h4>
            </a>
          </div>
          <div class="col-12 pt-1">
            <a routerLink="about">
              <h4>About Us</h4>
            </a>
          </div>
          <div class="col-12 pt-1">
            <a routerLink="solution">
              <h4>Solutions </h4>
            </a>
          </div>
          <div class="col-12 pt-1">
            <a routerLink="esgresource">
              <h4>ESG Resource Hub</h4>
            </a>
          </div>
          <div class="col-12 pt-1">
            <a routerLink="contactus">
              <h4>Contact Us</h4>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 pt-2">
        <h3 class="font-weight-bolder"><span id="footer-menu">Useful Links</span></h3>

        <div class="row pt-2 footerLink">
          <div class="col-12">
            <a href="javascript:void(0)">
              <h4>Subscribe</h4>
            </a>
          </div>
          <div class="col-12 pt-1">
            <a href="javascript:void(0)">
              <h4>Know More </h4>
            </a>
          </div>
          <div class="col-12 pt-1">
            <a href="javascript:void(0)">
              <h4>FAQ</h4>
            </a>
          </div>

        </div>
      </div>

      <div class="col-md-4 pt-2">
          <h3 class="font-weight-bolder"><span id="footer-menu">Keep In Touch</span></h3>
          <!-- <div class="row pt-2">
            <div class="col-3">
              <img class="img-fluid" src="assets/images/pages/home/icn_tw.png" alt="Twitter icon" width="50%"
                height="auto" />
            </div>
  
            <div class="col-3">
              <img class="img-fluid" src="assets/images/pages/home/icn_fb.png" alt="Facebook icon" width="50%"
                height="auto" />
            </div>
  
            <div class="col-3">
              <img class="img-fluid" src="assets/images/pages/home/icn_insta.png" alt="Instagram icon" width="50%"
                height="auto" />
            </div>
  
            <div class="col-3">
              <img class="img-fluid" src="assets/images/pages/home/icn_linkedin.png" alt="Linkedin icon" width="50%"
                height="auto" />
            </div>
          </div> -->
          <div class="row p-1">
            <!-- <div class="col-md-4"> -->
            <div>
              <img class="img-fluid pr-1" src="assets/images/pages/home/icn_tw.png" alt="Twitter icon" width="85%"
                height="auto" />
            </div>
            <div><img class="img-fluid pr-1" src="assets/images/pages/home/icn_fb.png" alt="Facebook icon" width="85%"
                height="auto" /></div>
            <div><img class="img-fluid pr-1" src="assets/images/pages/home/icn_insta.png" alt="Instagram icon"
                width="85%" height="auto" /></div>
            <div> <img class="img-fluid pr-1" src="assets/images/pages/home/icn_linkedin.png" alt="Linkedin icon"
                width="85%" height="auto" /></div>
            <!-- </div> -->
          </div>
       
        <div id="circle">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" width="300px" height="300px" viewBox="0 0 300 300" enable-background="new 0 0 300 300"
            xml:space="preserve">
            <defs>
              <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 " />
            </defs>
            <circle cx="150" cy="100" r="75" fill="none" />
            <g>
              <use xlink:href="#circlePath" fill="none" />
              <text fill="#000">
                <textPath xlink:href="#circlePath">REQUEST FOR DEMO . REQUEST FOR DEMO . REQUEST FOR DEMO . </textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">

  </div>
</div>


<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
<!--copyright -->
<div class="mt-3">
  <div class="text-center blocklightblue">
    Copyrights &copy; 2023 esgkonnect.in | Designed by NEPRA
  </div>
</div>