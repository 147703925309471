<!-- Main menu -->
<div class="row" id="content">
  <div class="col-md-2">
    <a class="navbar-brand">
      <span class="brand-logo">
        <a><img class="img-fluid" src="assets/images/logo/logo-top.png" class=" pt-2" alt="brand-logo" width="70%" height="auto" (click)="GotoHome()" ></a>
      </span>
      <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
    </a>
  </div>
  <!-- <div class="col-md-3"></div> -->
  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 pt-2">
    <div class="navbar-container main-menu-content">
      <ul class="nav navbar-nav d-flex justify-content-end" id="main-menu-navigation" layout="horizontal" core-menu></ul>
    </div>
  </div>
</div>
  <!-- <div class="col-md-2">
    <a class="navbar-brand float-left">
      <span class="brand-logo">
        <a><img src="assets/images/logo/nepra-logo.png" class="mt-1" alt="brand-logo" width="50%" (click)="GotoHome()"  /></a>
      </span>
      <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
    </a>
  </div> -->


<!--/ Main menu -->
