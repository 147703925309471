
<section id="content" class="section-1-footer">
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2">

            <div class="text-center">
                <h1 class="req main-title">Request For Demo ! </h1>
                <h4 class="text-white" >Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium et,<br> illo iusto distinctio autem neque! </h4>
                <p class="watermark">
                        R
                    </p>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="text-center">
                <div class="input-group ">
                    <input type="text" class="form-control form-control-lg" placeholder="Enter Email ID"
                        aria-describedby="button-addon2" />
                    <div class="input-group-append" id="button-addon2">
                        <button class="btn btn-success btn-lg" type="button" rippleEffect>Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        </div>
    </div>
</section>