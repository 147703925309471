import { CoreMenu } from '@core/types';


export const menu: CoreMenu[] = [
  // Menu bar
  {
    id: '',
    title: 'Home',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'item',
    url: 'home',
    icon: '',
    badge: {
      title: '',
      translate: 'MENU.DASHBOARD.BADGE',
    },
    children: []
  },

  {
    id: '',
    type: 'item',
    title: 'About Us',
    translate: 'MENU.APPS.SECTION',
    icon: '',
    url: 'about',
    children: [
     
    ]
  },

  {
    id: '',
    type: 'item',
    title: 'Solutions',
    translate: 'MENU.FT.SECTION',
    icon: '',
    url: 'solution',
    children: []
  },

  {
    id: '',
    type: 'item',
    title: 'ESG Resource Hub',
    translate: 'MENU.CM.SECTION',
    icon: '',
    url:'esgresource',
    children: []
    
  },
  // Others
  // {
  //   id: 'others',
  //   type: 'item',
  //   title: 'Contact Us',
  //   translate: 'MENU.OTHERS.SECTION',
  //   icon: '',
  //   url: 'faq',
  //   children: [
  //     {
  //       id: 'menu-levels',
  //       title: 'Menu Levels',
  //       translate: 'MENU.OTHERS.LEVELS.COLLAPSIBLE',
  //       icon: 'menu',
  //       type: 'collapsible',
  //       children: [
  //         {
  //           id: 'second-level',
  //           title: 'Second Level',
  //           translate: 'MENU.OTHERS.LEVELS.SECOND',
  //           icon: 'circle',
  //           type: 'item',
  //           url: '#'
  //         },
  //         {
  //           id: 'second-level1',
  //           title: 'Second Level',
  //           translate: 'MENU.OTHERS.LEVELS.SECOND1.COLLAPSIBLE',
  //           icon: 'circle',
  //           type: 'collapsible',
  //           children: [
  //             {
  //               id: 'third-level',
  //               title: 'Third Level',
  //               translate: 'MENU.OTHERS.LEVELS.SECOND1.THIRD',
  //               type: 'item',
  //               url: '#'
  //             },
  //             {
  //               id: 'third-level1',
  //               title: 'Third Level',
  //               translate: 'MENU.OTHERS.LEVELS.SECOND1.THIRD1',
  //               type: 'item',
  //               url: '#'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 'disabled-menu',
  //       title: 'Disabled Menu',
  //       translate: 'MENU.OTHERS.DISABLED',
  //       icon: 'eye-off',
  //       type: 'item',
  //       url: '#',
  //       disabled: true
  //     },
  //     {
  //       id: 'documentation',
  //       title: 'Documentation',
  //       translate: 'MENU.OTHERS.DOCUMENTATION',
  //       icon: 'file-text',
  //       type: 'item',
  //       url: ,
  //       externalUrl: true,
  //       openInNewTab: true
  //     },
  //     {
  //       id: 'raise-support',
  //       title: 'Raise Support',
  //       translate: 'MENU.OTHERS.SUPPORT',
  //       icon: 'life-buoy',
  //       type: 'item',
  //       url: 'https://pixinvent.ticksy.com/',
  //       externalUrl: true,
  //       openInNewTab: true
  //     }
  //   ]
  // },


  {
    id: '',
    type: 'item',
    title: 'Contact Us',
    translate: 'MENU.OTHERS.SECTION',
    icon: '',
    url:'contactus',
    children: []
  }

];
