import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/miscellaneous/error/error.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'home', loadChildren: () => import('./main/home/home.module').then((m) => m.HomeModule) },
  { path: 'about', loadChildren: () => import('./main/about/about.module').then((m) => m.AboutModule) },
  { path:'solution',loadChildren: ()=> import('./main/solution/solution.module').then((m)=>m.SolutionModule)},
  { path: 'esgresource', loadChildren: () => import('./main/esg-resource/esg-resource.module').then((m) => m.EsgResourceModule) },
  {path:'contactus',loadChildren:()=> import('./main/contactus/contactus.module').then((m)=>m.ContactusModule)},
  // {
  //   path: '**',
  //   redirectTo: '/miscellaneous/error' //Error 404 - Page not found
  // }
  { path: '**',
  component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
